
.MuiInputBase-input  {
    height: 30px !important;
  }
/* 
.MuiInput-root {
    padding-bottom: 3px;
} */

.MuiAutoComplete-inputRoot {
    height: 10px
}

.css-l3ln04-MuiAutocomplete-root .MuiInput-root .MuiInput-input {
    padding: 6px !important;
}